import * as React from "react";
import Logo from '../img/logo.png'

function SvgComponent(props) {
  return (  
    <div className="branding">
      <img src={Logo} width="120" alt="olethub" />
    </div>
  );
}

export default SvgComponent;
